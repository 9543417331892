<template>
            <div 
                    v-if="event.dataStatus == true "
            > 
                      <KunstwerkenThumbnails  
                            v-if="event.typeLayoutId == 1"
                          />

                      <KunstwerkenThumbnails  
                            v-else-if="event.typeLayoutId == 3"
                          />

                      <KunstwerkenLayout  
                            v-else-if="event.typeLayoutId == 2"
                          />

                      <ContactLayout                       
                               v-else-if="event.typeLayoutId == 9"
                              />

             </div>

             <div v-else>

                    <p>Data wordt geladen</p>

            </div>

</template>


<script>


/*

                      <HomeLayout                       
                            v-if="event.rubriekData.type_layout_id == 1"
                            />



                                 <p>HomeLayout {{event.rubriekData.type_layout_id}}</p>
                             <p>KunstwerkenLayout {{event.rubriekData.type_layout_id}}</p>
                                <p>ContactLayout {{event.rubriekData.type_layout_id}}</p>





                      <div                       
                               v-else-if="event.rubriekData.type_layout_id == 9">

                                <p>ContactLayout {{event.rubriekData.type_layout_id}}</p>
                              <ContactLayout  />
                      </div>


                  <MapAdres />


                    v-else-if="event.geotreeGeladen"


 

  */



//import HomeLayout from '@/components/HomeLayout.vue'
import KunstwerkenThumbnails    from '@/components/KunstwerkenThumbnails.vue'
import KunstwerkenLayout             from '@/components/KunstwerkenLayout.vue'
import ContactLayout                          from '@/components/ContactLayout.vue'


import { 
      reactive, 
      computed,

} from 'vue';

import store   from '@/store' ;



export default {
      name: 'Rubriek',

      components: {
           //   HomeLayout,
            KunstwerkenThumbnails,
            KunstwerkenLayout,
            ContactLayout,
     },


    setup ( ) {

        const event = reactive ({


             //   tekstKeuze: 0,

                dataStatus: computed (() => { 

                              // if ( event.rubriekData == "undefined"  )
                              // {
                              //         return false;
                              // }

                              // if ( event.rubriekData ==  null  )
                              // {
                              //         return false;
                              // }


                              // if ( event.rubriekData == "undefined"  )
                              // {
                              //         return false;
                              // }

                              // if ( event.rubriekData ==  null  )
                              // {
                              //         return false;
                              // }


                              if ( event.typeLayoutId  == 0  )
                              {
                                      return false;
                              }

                              // if ( event.rubriekData.type_layout_id === "undefined"  )
                              // {
                              //         return false;
                              // }

                              return  true;
                }),

    



                    typeLayoutId: computed ((  ) => { 

                        //  return [];
                          let k = store.state.menuRubriekenKeuze;

                          let a = store.getters.getMenuRubriekenData ();



                          let d = a.filter ( c => c.rubriek_id == k );


                          if ( d === null  )
                          {
                                return 0;
                          }


                        if ( d.length < 1 )
                          {
                                return 0;
                          }


                          if ( d [ 0 ] === "undefined"  )
                          {
                                return 0;
                          }

                          // if ( d [ 0 ].type_layout_id == 2 )
                          // {
                          //         window.scrollTo(0,0);
                          // }



         //  window.scrollTo(0,0);


                          return d [ 0 ].type_layout_id ;
                    }),




                    data: computed ((  ) => { 

 //    window.scrollTo(0,0);




                            return store.getters.getTekstData ();
                    }),


                    rubriekData: computed ((  ) => { 

 //    window.scrollTo(0,0);

                            return store.getters.getMenuRubriekenData ();
                    }),




                    tekstkeuzedata: computed ((  ) => { 

   // window.scrollTo(0,0);
                            return store.getters.getTekstKeuzeData ();
                    }),

            });





            function initPaginaData () {


                  //--------------------------------------------//


                  store.dispatch ( 'setMenuStatus', 'close' );


                  //--------------------------------------------//


                 // if ( event.rubriekData.length > 0 )
                 //  {
                  
                          let id = this.$route.query.i;

                           store.dispatch ( 'setMenuRubriekKeuze', id );
                  // }


                          // if ( event.data.length > 0 )
                          // {
                                  let pid = this.$route.query.p;

                                   if ( pid > 0 )
                                   {

                                          store.dispatch ( 'setTekstKeuze', pid );
                                   }


                                   // else
                                   // {
                                   //       store.dispatch ( 'initTekstKeuze' );   
                                   // }                 
                          // }



                 store.dispatch ( 'laadMenuRubrieken' ); 
                store.dispatch ( 'laadTekst' ); 



            }


            return { 
                   event, 
                   initPaginaData,
            };
       },




        beforeMount() {

                    // console.log( "AdresPagina: beforeMount" );

                   // this.initPaginaData();
                             //     window.scrollTo(0,0);
        },


          mounted () {

           //      console.log( "Rubriek: mounted" );

                 this.initPaginaData();

               //window.scrollTo(0,0);
          },


          updated () {

         //       console.log( "Rubriek: updated" );

               this.initPaginaData();

          //     window.scrollTo(0,0);
          },

}


</script>



<style>



</style>