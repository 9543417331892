
<template>

       <div 
                 v-if=" event.tekstkeuzedata != null "

                 class="pagina_blok"
                 >


        <h1 class="koptekst">{{event.tekstkeuzedata.naam}}</h1>

            <div class="navigatie_buttons">



                        <router-link 
                              v-if=" event.navigatie.vorige > 0 "

                                  :to="{path:'/r',  query:{i: event.rubriekkeuze, p: event.navigatie.vorige, info: event.navigatie.vorige_url}}"


                                class="button "                        
                          >
                        </router-link>



                        <router-link 
                              v-if=" event.navigatie.volgende > 0 "

                                  :to="{path:'/r',  query:{i: event.rubriekkeuze, p: event.navigatie.volgende, info: event.navigatie.volgende_url}}"

                                class="button button_plus"                        
                          >
                        </router-link>



              </div>








                <div 
                      v-if=" event.tekstkeuzedata.beelden !== null "
                      class="foto_blok slowshow">

                          <div 
                                    v-for="(value_beeld, key_beeld) in event.tekstkeuzedata.beelden" 
                                     :key="key_beeld" 

                                     class="beeld"
                                    >

                                    <img 
                                            v-bind:src="value_beeld.img"
                                            >

                                    <p
                                        v-if="value_beeld.tekst[ 1 ] != '' "
                                        ><span v-html="value_beeld.tekst[ 1 ]"></span>
                                    </p>
                                   <p
                                        v-else
                                        >
                                        <br>
                                    </p>



                                    <div 
                                                v-on:click="setFotoKeuze ( value_beeld.id )" 
                                                v-bind:class="[ isHartje ( value_beeld.id ) ]"  
                                    ></div>

                          </div>
                </div>





                <ul  
                        v-if="event.data.length > 1 "
                        class="links_blok"
                        >

                             <li 
                                    v-for="(value_tekst, key_tekst) in event.data" 
                                    :key="key_tekst" 
                                    v-bind:class="[event.tekstkeuze == value_tekst.tekst_id ? event.activeClass : event.standClass, '' ]"  
                                    >

                                        <router-link     
                                                 :to="{path:'/r',  query:{i: value_tekst.rubriek_id, p: value_tekst.tekst_id, info: value_tekst.url }}"
                                                >
                                            
                                                {{value_tekst.naam}}

                                        </router-link>
                             </li>
                  </ul>


      </div>



</template>


<script>

/*

{{event.tekstkeuze}}


*/



import { reactive, computed } from 'vue';
import store from '@/store'


export default {

    name: 'KunstwerkenLayout',

    // props: {

    //      //   selectie: String,
    // },

    components: {
      //      LoadingImg
          
    },



    setup ( ) {

        const event = reactive ({

    //            imgIsLoaded: false,


     //           tekstkeuzenu: 0,

           //     volgende: 147,


                kop: '',


                data: computed ((  ) => { 

                    //  return [];
                      let k = store.state.menuRubriekenKeuze;

                      let a = store.getters.getTekstData ();

                      let d = a.filter ( c => c.rubriek_id == k );


                      // if ( d != null  )
                      // {
                      //       store.dispatch ( 'setTekstKeuze', d[0].tekst_id  );
                      // }

                      return d;
                }),




                 tekstkeuze: computed (() => {

                            console.log ( "kunstwerkenLayout:  tekstkeuze scroll into view top " );

                            document.getElementById( 'app' ).scrollIntoView( {block: "start", inline: "nearest"} );              

                            return  store.getters.getTekstKeuze();
                }),



                tekstkeuzedata: computed ((  ) => { 

                     return store.getters.getTekstKeuzeData ();
                }),



                getFotoKeuzeLijst: computed ((  ) => { 

                  //   return store.state.tekstKeuzeData;

                     return store.state.fotoKeuzeLijst;
                }),





                navigatie: computed ((  ) => { 

                    //  return [];
                      let k = store.state.menuRubriekenKeuze;

                      let a = store.getters.getTekstData ();

                      let n = { vorige: 0, volgende: 0 }

                      let d = a.filter ( c => c.rubriek_id == k );

                      let tk = store.state.tekstKeuze;


                      let gevonden = false;


                      if ( d == null ) {

                              return n;
                      }

                      if ( d.length < 2 ) {

                              return n;
                      }



                      for ( let i = 0;  i <  d.length;  i++ ) {

                            let t =  d [ i ].tekst_id;


                            if (( gevonden ) && ( n.volgende == 0 ))
                            {
                                    n.volgende = t;
                                    n.volgende_url = d [ i ].url;
                            }

                            else
                            {
                                      if ( t == tk ) 
                                      {
                                              gevonden = true;
                                      }
                                      else
                                      {
                                                if ( !gevonden )
                                                {
                                                       n.vorige = t;
                                                        n.vorige_url  = d [ i ].url;
                                                }
                                                
                                      }
                            }
                      }


                      if ( n.vorige == 0 )
                      {
                              n.vorige = d [ d.length - 1 ].tekst_id;
                              n.vorige_url = d [ d.length - 1 ].url;
                      }


                      if ( n.volgende == 0 )
                      {
                              n.volgende = d [ 0 ].tekst_id;
                              n.volgende_url = d [ 0 ].url;
                      }

                     // window.scrollTo(0,0);

                      //document.getElementById('app').scrollIntoView();

                      return n;
                }),




                 rubriekkeuze: computed (() => {

                       //alert ( store.state.menu_status);

                       // event.imgIsLoaded = false;

                        return store.state.menuRubriekenKeuze;
                }),







                activeClass: 'lijst_select',
                standClass: '',
        });



       function isHartje ( id ) { 

                let a = event.getFotoKeuzeLijst;


              if ( a == null ) {

                      return 'hartje' ;
              }

              if ( a.length < 1 ) {

                      return 'hartje' ;
              }


                if ( a.includes ( id ) )
                  {
                            return 'hartje select';
                  }


                    return 'hartje' ;
        }





        function vertaal ( code ) {

                //return code ;
                return store.getters.getVertaling ( code );
        }



        function initPaginaData ( scroll_to_top ) {


                if ( scroll_to_top )
                {
                        console.log ( "kunstwerken scroll into view top " );

                        document.getElementById( 'app' ).scrollIntoView( {block: "start", inline: "nearest"} );                   
                }
        

                    if ( event.tekstkeuzedata != null )
                    {
                                document.title = event.tekstkeuzedata.naam + ' | '  + this.vertaal ( 'naam_bedrijf' );
                    }
                    else
                    {
                                document.title = this.vertaal ( 'naam_bedrijf' );
                    }

              //  window.scrollTo (0, 0 );

        }




            function setFotoKeuze ( id ) {

                   store.dispatch ( 'setFotoKeuze', id );
            }




        return { 
              event, 
              initPaginaData,
              vertaal,
              setFotoKeuze,
              isHartje,
        };
    },




          mounted () {

                console.log ( "kunstwerkenLayout mounted " );


                this.initPaginaData ( true );

              // console.log( "AdresPagina: updated" );

               //event.imgIsLoaded = false;
          },



          updated () {

                console.log ( "kunstwerkenLayout updated " );

                this.initPaginaData ( false );

              // console.log( "AdresPagina: updated" );

               //event.imgIsLoaded = false;
          },

}



</script>



<style scoped>




.select 
{

        font-size: 2rem;
}



/*/////////////////////////////////////*/
/*  */
/*/////////////////////////////////////*/



.pagina_blok 
{
      margin:  1rem auto;

      word-break: break-word;

}




.foto_blok 
{
        margin:  1rem 0;
/*      max-width:  40rem;*/

      text-align: center;


}


ul.links_blok 
{
      margin:  1rem;
      padding:  0;

      list-style: none;

/*      line-height: 2.4rem;
*/}



ul.links_blok a
{
        display: block;

        margin:  0;

        padding:  .5rem 0 .5rem 1rem;


      color: rgb( 200, 200, 200 );
      color: rgb( 180, 180, 180 );
      color: rgb( 160, 160, 160 );

      text-decoration: none;

    border-bottom: 1px solid rgb( 240, 240, 240 );

}


ul.links_blok a:hover
{
      color: rgb( 120, 120, 120 );

      text-decoration: none;

/*
    background-color: rgb( 240, 240, 240 );*/
}


ul.links_blok .lijst_select a
{
        color: rgb( 120, 120, 120 );
        color:  rgb( 63, 193, 255 );

        font-size: 1.2em;
        font-weight: 600;

/*        background-color: rgb( 240, 240, 240 );
*/}


ul.links_blok li
{
}


ul.links_blok li:hover
{
}







.slowshow 
{
/*      animation-name: fadein;
      animation-duration: 3s;
      animation-delay: 2s;
      animation-fill-mode: forwards;
      animation-fill-mode: both;*/
}



@keyframes fadein 
{
        0%              { opacity: .4; }
        100%         { opacity: 1; }
}





.koptekst 
{
            margin:  0 1rem 2rem 1rem;
            font-size: 1.4rem;
            line-height: 1.1em;
}





.beeld
{
    position: relative;
    margin:  0;

    padding:  1rem 1rem 1rem 1rem;

    text-align: center;

    background-color: rgb( 255, 255, 255 );

/*            max-height: calc( 100vh - 20rem) ;*/
}



.beeld p
{
        display: block;

        padding:  .5rem;

        font-size: 1rem;
        line-height: 1.2em;

      color:  rgb( 160, 160, 160 );
/*      max-width: 100%;
*/}


.beeld img
{
        display: inline-block;

        margin:  0;
        padding:  0;

        max-height: calc( 100vh - 10rem) ;
}


.navigatie_buttons 
{
        line-height: 2.5rem;
}


.hartje 
{
            margin:  0 auto;
            width:  2rem;
            height:  2rem;

            background-image: url( ~@/assets/hartje.svg ) ; 
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 2rem 2rem;     
}

.hartje:hover 
{
            background-image: url( ~@/assets/hartje-s.svg ) ; 

            animation: hVergroot linear .5s;
            animation-timing-function: ease;
            animation-iteration-count: 1;
            transform-origin: 50% 50%;
            animation-fill-mode: forwards;     
}


.hartje.select 
{
        background-image: url( ~@/assets/hartje-select.svg ) ; 
}




@keyframes hVergroot {

        0% {
              transform: scale(1, 1);
      }

        50% {
          transform: scale(1.2, 1.2);
        }

        100% {
          transform: scale(1, 1);
        }  
}







@media (min-width: 60rem) 
{
        .pagina_blok 
        {
              margin:  1rem auto;

              width:  44rem;

              display: grid;
              grid-gap: 2rem;
              grid-row-gap: 1rem;

              grid-template-columns: 44rem;  
              grid-template-rows: 2rem  2rem 1fr ;  
        }


        .koptekst 
        {
              grid-row-start: 1;
              grid-row-end: span 1;
              grid-column-start: 1;
              grid-column-end: span 1;

              font-size: 2rem;
        }


        .navigatie_buttons 
        {
                grid-row-start: 2;
                grid-row-end: span 1;
                grid-column-start: 1;
                grid-column-end: span 1;

                line-height: 2.5rem;
        }



        .foto_blok 
        {
              grid-row-start: 3;
              grid-row-end: span 1;
              grid-column-start: 1;
              grid-column-end: span 1;

              text-align: center;
        }



        .links_blok 
        {
            grid-row-start: 4;
            grid-row-end: span 1;
            grid-column-start: 1;
            grid-column-end: span 1;
            text-align: left;
        }


          ul.links_blok 
          {
/*                position: relative;
*/                padding:  0 0 2rem 0;
          }


            ul.links_blok a
            {

                    font-size: .9rem;

                    padding:  .75rem 0 .75rem .5rem;
            }


            ul.links_blok  li:first-child a
            {    
                border-top: 1px solid rgb( 240, 240, 240 );
            }



            ul.links_blok  li a:hover
            { 

                    animation: linkVergroot linear .5s;
                    animation-timing-function: ease;
                    animation-iteration-count: 1;
                    transform-origin: 50% 50%;
                    animation-fill-mode: forwards;     

                    background-color: rgb( 255, 255, 255 );
                    border-top: 1px solid rgb( 240, 240, 240 );

                    border-color: rgb( 63, 193, 255 );
                    border: 1px solid rgb( 63, 193, 255 );
              }





        .beeld
        {
            margin:  0 0 3rem 0;

            line-height: 0;

            border: 1px solid rgb( 240, 240, 240 );

        }

        .beeld p
        {
                padding:  1rem 0 1rem 0;

                line-height: 1.2em;
        }


        .hartje 
        {
                    position: absolute;

                    right:  0;
                    bottom:  0;
        }

}









@media (min-width: 70rem) 
{
        .pagina_blok 
        {
              margin:  1rem auto;
              width:  100%;

              display: grid;

/*              grid-gap: 2rem;*/
              grid-template-columns: 1fr  44rem 1fr;  
              grid-template-rows: 2rem  2rem 1fr;  
        }


        .koptekst 
        {
              grid-row-start: 1;
              grid-row-end: span 1;
              grid-column-start: 1;
              grid-column-end: span 3;

              font-size: 2rem;
        }


        .navigatie_buttons 
        {
                grid-row-start: 2;
                grid-row-end: span 1;
                grid-column-start: 2;
                grid-column-end: span 1;

                line-height: 2.5rem;
        }



        .foto_blok 
        {
              grid-row-start: 3;
              grid-row-end: span 1;
              grid-column-start: 2;
              grid-column-end: span 1;

              text-align: center;
        }



        .links_blok 
        {
            grid-row-start: 4;
            grid-row-end: span 1;
            grid-column-start: 2;
            grid-column-end: span 1;
            text-align: left;
        }




    }



@media (min-width: 90rem) 
{
        .pagina_blok 
        {
              margin:  2rem auto 0 auto;

              display: grid;
              grid-gap: 4rem;
              grid-row-gap: 2rem;

              grid-template-columns: 1fr  52rem 1fr;  
              grid-template-rows: 2rem  2rem 1fr;  
        }

        .links_blok 
        {
            grid-row-start: 3;
            grid-row-end: span 1;
            grid-column-start: 1;
            grid-column-end: span 1;
            text-align: left;
        }



          ul.links_blok 
          {
                padding:  0 1rem 2rem 2rem;
          }

        .links_blok 
        {
                    max-width: 20rem;
        }


    }



@keyframes linkVergroot {

        0% {
              transform: scale(1, 1);
      }

        100% {
          transform: scale(1.07, 1.07);
        }  
}






.button
{
      display: inline-block;

      margin:  0 1rem;

      height:  2rem;
      width:  2rem;


      border:  1px solid rgb( 220, 220, 220 );

      border-radius: 2rem;


      background-image: url( ~@/assets/pijl-links.svg ) ; 
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 2rem 2rem;     
}


.button.button_plus
{
            background-image: url( ~@/assets/pijl-rechts.svg ) ; 

}









</style>
